'use client';

import React, {FC, useState} from 'react';
import styles from './Header.module.scss';
import {togleMenu} from '@/app/features/MainPage/mainSlice/MainSlice';

import {useAppDispatch, useAppSelector} from '@/app/redux/hooks';
import {Burger, Close, Logo} from '@/app/assets/icons';
import Image from 'next/image';
import {usePathname} from 'next/navigation';
import NavBar from '../NavBar';
import Link from 'next/link';

interface HeaderProps {
  className?: string;
}

const Header: FC<HeaderProps> = ({className = ''}) => {
  const [search, setSearch] = useState<string>('');
  const [searchBar, setSearchBar] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {isOpenMenu} = useAppSelector((state) => state.authorization);
  const path = usePathname();
  const pageWithoutHeader = path.includes('/buy-ticket/');

  const handleOpenMenu = () => {
    dispatch(togleMenu(!isOpenMenu));
    setSearchBar(false);
  };

  const handleSearchEvent = (value: string) => {
    setSearch(value);
  };

  const handleOpenSearchBar = () => {
    setSearchBar(!searchBar);
  };

  return (!pageWithoutHeader && (
      <header
        className={`${styles.root} ${
          isOpenMenu ? styles.root__open : styles.root__closed
        } ${className}`}>
        <button className={styles.header__burger} onClick={handleOpenMenu} aria-label="open menu">
          {isOpenMenu ? <Image src={Close} alt="Close"/> : <Image src={Burger} alt="Burger"/>}
        </button>

        <span
          className={`${styles.header__logo} ${
            styles[`header__logo--${!searchBar ? 'open' : 'closed'}`]
          }`}>
          <Link href={'/'}>
            <Image src={Logo} alt="logo"/>
          </Link>
        </span>
        {/*{!isOpenMenu && (*/}
        {/*  <div className={`${styles.header__user} `}>*/}
        {/*    <div className={`${styles.userblock}`}>R</div>*/}
        {/*  </div>*/}
        {/*)}*/}
        <NavBar isMobile/>
      </header>)

  );
};

export default Header;
