'use client';
import { Provider } from 'react-redux';
import { store } from './store';
import {BackgroundImageProvider} from "@/shared/BackgroundImageComponent/BackgroundImageProvider";

interface ProvidersProps {
  children: React.ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  return <Provider store={store}>
    <BackgroundImageProvider>
      {children}
    </BackgroundImageProvider>
  </Provider>;
}
