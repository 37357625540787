import Logo from './logo.svg';
import ArrowRight from './arrow-right.svg';
import Search from './seach.svg';
import Location from './location.svg';
import Mark from './mark.svg';
import Clock from './clock.svg';
import CounterPlus from './counter-plus.svg';
import CounterMinus from './counter-minus.svg';
import ArrowBack from './arrow-back.svg';
import Expand from './expand.svg';
import Telegram from './telegram.svg';
import Alarm from './alarm.svg';
import LinkIcon from './link.svg';
import Success from './success.svg';
import Download from './download.svg';
import Close from './close.svg';
import Burger from './burger.svg';
import TicketActive from './ticket_active.svg';
import TicketArrow from './ticket_arrow.svg';
import TicketDisable from './ticket_disable.svg';
import TicketGift from './ticket_gift.svg';
import TicketEye from './ticket_eye.svg';

export {
  TicketActive,
  TicketArrow,
  TicketDisable,
  TicketGift,
  TicketEye,
  Alarm,
  Success,
  Close,
  Burger,
  Download,
  ArrowRight,
  ArrowBack,
  Clock,
  CounterPlus,
  CounterMinus,
  Expand,
  Mark,
  Location,
  LinkIcon,
  Search,
  Telegram,
  Logo,
};
