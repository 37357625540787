import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getEvents,
  getSingleEvent,
  buyTickets,
  ticketStatusPolling,
} from './EventActions';
import {
  CreateEventResponse,
  EventsArr,
  InvoiceResponse,
  EventTicketPolling,
  SingleEventInfo,
} from '@/models/EventModels';

interface EventsState {
  isLoading: boolean;
  jars_loading: boolean;
  error: string;
  jars_error: null | {
    code: string;
    detail: string;
  };
  create_event: CreateEventResponse | null;
  events: EventsArr | null;

  token_monobank: string;
  event_jars: InvoiceResponse | null;
  ticket_data: EventTicketPolling | null;
  single_event: SingleEventInfo | null;
}

const initialState: EventsState = {
  isLoading: false,
  jars_loading: false,
  error: '',
  jars_error: null,
  events: null,
  create_event: null,
  token_monobank: '',
  event_jars: null,
  ticket_data: null,
  single_event: null,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    editTokenMonobank: (state, action) => {
      state.token_monobank = action.payload;
    },
  },
  extraReducers: {
    //GET LIST OF JARS
    [buyTickets.fulfilled.type]: (state, action: PayloadAction<InvoiceResponse>) => {
      (state.jars_loading = false),
        (state.jars_error = null),
        (state.event_jars = action.payload),
        (state.ticket_data = null);
    },
    [buyTickets.pending.type]: (state) => {
      (state.jars_loading = true), (state.jars_error = null);
    },
    [buyTickets.rejected.type]: (
      state,
      action: PayloadAction<{ code: string; detail: string }>,
    ) => {
      (state.jars_loading = false), (state.jars_error = action.payload);
    },

    //TICKET POLLING

    [ticketStatusPolling.fulfilled.type]: (state, action: PayloadAction<EventTicketPolling>) => {
      (state.isLoading = false), (state.error = ''), (state.ticket_data = action.payload);
    },
    [ticketStatusPolling.pending.type]: (state) => {
      state.isLoading = true;
    },
    [ticketStatusPolling.rejected.type]: (state, action: PayloadAction<string>) => {
      (state.isLoading = false), (state.error = action.payload);
    },

    //GET ALL EVENTS FOR ONE ORGANIZATION

    [getEvents.fulfilled.type]: (state, action: PayloadAction<EventsArr>) => {
      (state.isLoading = false), (state.error = ''), (state.events = action.payload);
    },
    [getEvents.pending.type]: (state) => {
      (state.isLoading = true), (state.events = null);
    },
    [getEvents.rejected.type]: (state, action: PayloadAction<string>) => {
      (state.isLoading = false), (state.error = action.payload);
    },

    //GET SINGLE EVENT

    [getSingleEvent.fulfilled.type]: (state, action: PayloadAction<SingleEventInfo>) => {
      (state.isLoading = false), (state.error = ''), (state.single_event = action.payload);
    },
    [getSingleEvent.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getSingleEvent.rejected.type]: (state, action: PayloadAction<string>) => {
      (state.isLoading = false), (state.error = action.payload);
    },
  },
});

export const { editTokenMonobank } = eventSlice.actions;
export default eventSlice.reducer;
