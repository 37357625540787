'use client';
import {FC, useEffect, useState} from 'react';
import {useBackgroundImage} from "@/shared/BackgroundImageComponent/BackgroundImageProvider";
import styles from "./BackgroundImageComponent.module.scss";


interface BackgroundImageComponentProps {
  className?: string;
}

const BackgroundImageComponent: FC<BackgroundImageComponentProps> = ({
    className = '',
}) => {
  const { imageUrl } = useBackgroundImage();
  const [backgroundStyles, setBackgroundStyles] = useState({ backgroundImage: '', opacity: 0 });

  useEffect(() => {
    const image = "linear-gradient(0deg, rgba(12,11,12,1) 0%, transparent 100%), " + imageUrl;
    if (image === backgroundStyles.backgroundImage) return;

    setBackgroundStyles(prevState => ({...prevState, opacity: 0}));

    const timeoutId = setTimeout(() => {
        setBackgroundStyles({ backgroundImage: image, opacity: 0.2 });
    }, 1000);

    return () => clearTimeout(timeoutId);

  }, [imageUrl]);

  return (
      <div className={styles.root} style={backgroundStyles} id={"background"}/>
  )

};

export default BackgroundImageComponent;
