import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/src/app/[locale]/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/src/app/assets/styles/main.scss");
;
import(/* webpackMode: "eager" */ "/var/www/gligle-app-front-next/src/app/[locale]/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/var/www/gligle-app-front-next/src/app/redux/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/shared/BackgroundImageComponent/BackgroundImageComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/shared/FooterComponent/FooterComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/shared/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/shared/NavBar/NavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/shared/RootWrapper/RootWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/gligle-app-front-next/src/shared/SearchConsole/SearchConsole.tsx");
