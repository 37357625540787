import axios from "axios";

export default async function addOrganizerWaitlist(
  name: string,
  contacts: string,
) {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC__APP_API_URL_V1}experiments/organizer-waitlist/`,
      {
        name,
        contacts,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Failed save organizer", error);
    throw error;
  }
}
