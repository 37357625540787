import { combineReducers, configureStore } from '@reduxjs/toolkit';
import mainReducer from '../features/MainPage/mainSlice/MainSlice';
import eventsReducer from '../features/BuyTicket/sliceEvent/EventSlice';
import authorizationReducer from '../features/Auth/sliceAuth/AuthSlice';

const rootReducer = combineReducers({
  main: mainReducer,
  events: eventsReducer,
  authorization: authorizationReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
