'use client';
import { FC } from 'react';
import styles from './SearchComponent.module.scss';
import Image from 'next/image';
import Search from '../../app/assets/icons/search_icon.svg';
import { useTranslations } from 'next-intl';

interface SearchComponentProps {
  className?: string;
}

const SearchComponent: FC<SearchComponentProps> = ({ className = '' }) => {
  const t = useTranslations('SEARCH_COMPONENT');
  return (
    <label htmlFor="search" className={`${styles.root} ${className}`} role="search">
      <Image src={Search} alt="search" />
      <input id={'search'} type="text" placeholder={t('what')} />
    </label>
  );
};

export default SearchComponent;
