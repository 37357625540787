'use client';
import {FC, useEffect, useState} from 'react';
import styles from './SearchConsole.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import Logo from '../../app/assets/icons/logo.svg';
import SearchComponent from '../SearchComponent';
import Globus from '../../../src/app/assets/icons/globus.svg';
import Person from '../../app/assets/icons/person.svg';
import Logout from '../../../src/app/assets/icons/logout.svg';
import {useLocale, useTranslations} from 'next-intl';
import {usePathname, useRouter} from '@/i18n/routing';
import AuthPopup from '../AuthPopup';
import WaitlistPopupContainer from "@/shared/WaitlistPopupContainer";
import classNames from "classnames";
import {useLocalStorage} from "usehooks-ts";

interface SearchConsoleProps {
  className?: string;
}

const SearchConsole: FC<SearchConsoleProps> = ({className = ''}) => {
  const [isOpenAuthForm, setIsOpenAuthForm] = useState<boolean>(false);
  const router = useRouter();
  const pathname = usePathname();
  const t = useTranslations('SEARCH_CONSOLE');

  const [token, setToken] = useLocalStorage<string>('token', '')
  const [waitlistPopupOpen, setWaitlistPopupOpen] = useState<boolean>(false);
  const locale = useLocale();

  const handleChangeLanguage = (e: any) => {
    e.preventDefault()
    if (locale.includes('en')) {
      router.replace({pathname}, {locale: 'ua'});
    } else {
      router.replace({pathname}, {locale: 'en'});
    }
  };

  const handleAuthorize = () => {
    if (!token) {
      setIsOpenAuthForm(true);
    } else {
      localStorage.clear()
      window.location.reload()
    }
  };

  return (
    <>
      <header className={classNames(styles.root, className)}>
        <div className={styles.container__left}>
          <Link prefetch={false} href="/">
            <Image src={Logo} alt="logo"/>
          </Link>
          <SearchComponent/>
        </div>

        <div className={styles.container__right}>
          <button
            className={`${styles.header__button} ${styles.header__button__text}`}
            aria-label={t('sell')}
            onClick={() => setWaitlistPopupOpen(true)}
          >
            <p>{t('sell')}</p>
          </button>
          <button
              onClick={handleChangeLanguage}
              className={`${styles.header__button} ${styles.header__button__icon}`}
              aria-label="change language"
              type="button">
            <Image src={Globus} alt="globus"/>
          </button>

          {token && (
              <>
                {/*<button className={`${styles['header__button--profile']}`} type="button">*/}
                {/*  <span>*/}
                {/*    <Image src={Person} alt="person" />*/}
                {/*  </span>*/}
                {/*  <p> {t('profile')}</p>*/}
                {/*</button>*/}
                <button
                    onClick={handleAuthorize}
                    className={`${styles.header__button} ${styles.header__button__icon}`}
                    aria-label="logout"
                    type="button">
                  <Image src={Logout} alt="globus"/>
                </button>
              </>
          )}
          {!token && (
              <button
                  onClick={handleAuthorize}
                  className={`${styles.header__button} ${styles.header__button__icon}`}
                  aria-label="log in"
                  type="button">
                <Image src={Person} alt="person"/>
              </button>
          )}
        </div>
      </header>
      <WaitlistPopupContainer
        isOpen={waitlistPopupOpen}
        setIsOpen={setWaitlistPopupOpen}
      />
      <AuthPopup setIsOpen={() => setIsOpenAuthForm(false)} isOpen={isOpenAuthForm}/>
    </>
  );
};

export default SearchConsole;
