import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  CreateEventResponse,
  InvoiceResponse,
  EventTicketPolling,
} from '@/models/EventModels';

import {AxiosError} from 'axios';
import {useLocalStorage} from "usehooks-ts";

interface ErrorResponse {
  code: string;
  detail: string;
}

export interface ErrorInvoiceResponse extends ErrorResponse {
  invoice: InvoiceResponse;
}

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

function isErrorResponse(data: unknown): data is ErrorResponse {
  return (data as ErrorResponse).code !== undefined && (data as ErrorResponse).detail !== undefined;
}

function isErrorInvoiceResponse(data: unknown): data is ErrorInvoiceResponse {
  return isErrorResponse(data) && (data as ErrorInvoiceResponse).invoice !== undefined;
}

interface GetEventsOptions {
  status?: string;
  start_gte?: string;
}

export const getEvents = createAsyncThunk<CreateEventResponse[], GetEventsOptions | undefined>(
  'GET_EVENTS',
  async (options, thunkAPI) => {
    try {
      const mergedHeaders = {
        'X-ORGANIZATION-ID': localStorage.getItem('organizationID'),
      };

      const queryParams: { [key: string]: string } = {};
      if (options?.status !== undefined && options.status !== '') {
        queryParams.status = options.status;
      }
      if (options?.start_gte !== undefined && options.start_gte !== '') {
        queryParams.start_gte = options.start_gte;
      }

      const response = await axios.get<CreateEventResponse[]>(
        `${process.env.NEXT_PUBLIC__APP_API_URL}/events/`,
        {
          headers: mergedHeaders,
          params: queryParams,
        },
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('The request is failure');
    }
  },
);

export const getSingleEvent = createAsyncThunk(
  'GET_SINGLE_EVENT',
  async (body: { event_id: string; organization_id: string }, thunkAPI) => {
    try {
      const mergedHeaders = {
        'X-ORGANIZATION-ID': body.organization_id,
      };

      const response = await axios.get<CreateEventResponse[]>(
        `${process.env.NEXT_PUBLIC__APP_API_URL}/events/${body.event_id}/`,
        {
          headers: mergedHeaders,
        },
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('The request is failure');
    }
  },
);

export const buyTickets = createAsyncThunk<
  InvoiceResponse,
  { id_event: string; quantity: string },
  { rejectValue: ErrorResponse | ErrorInvoiceResponse | string }
>(
  'BUY_TICKETS',
  async (body: { id_event: string; quantity: string }, {rejectWithValue}) => {
    try {
      const mergedHeaders = {
        Authorization: `Token ${(localStorage.getItem('token') || "").replaceAll("\"", "")}`,
      };
      const response = await axios.post<InvoiceResponse>(
        `${process.env.NEXT_PUBLIC__APP_API_URL_V1}customer/events/${body.id_event}/buy/`,
        {ticket_count: +body.quantity},
        {
          headers: mergedHeaders,
        },
      );
      return response.data;
    } catch (e) {
      const axiosError = e as AxiosError;

      if (axiosError.response) {
        if (axiosError.response.status === 401) {
          return rejectWithValue('Invalid token');
        } else if (axiosError.response.data) {
          if (isErrorInvoiceResponse(axiosError.response.data))
            return rejectWithValue(axiosError.response.data as ErrorInvoiceResponse);
          if (isErrorResponse(axiosError.response.data))
            return rejectWithValue(axiosError.response.data as ErrorResponse);
        }
        if (axiosError.response.status === 500) {
          return rejectWithValue('Server error');
        }
      }

      return rejectWithValue('An error occurred during the request');
    }
  },
);

export const ticketStatusPolling = createAsyncThunk<
  EventTicketPolling,
  string,
  { rejectValue: string }
>('GET_ADDED_JAR', async (uuid, {rejectWithValue}) => {
  const mergedHeaders = {
    Authorization: `Token ${(localStorage.getItem('token') || "").replaceAll("\"", "")}`,
  };
  try {
    const response = await axios.get<EventTicketPolling>(
      `${process.env.NEXT_PUBLIC__APP_API_URL}tickets/${uuid}/`,
      {
        headers: mergedHeaders,
      },
    );
    return response.data;
  } catch (e) {
    return rejectWithValue('The request has failed');
  }
});

export const getPendingInvoice = createAsyncThunk<
  InvoiceResponse,
  string,
  { rejectValue: string }>
('GET_PENDING_INVOICE', async (_, {rejectWithValue}) => {
    const mergedHeaders = {
      Authorization: `Token ${(localStorage.getItem('token') || "").replaceAll("\"", "")}`,
    };
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC__APP_API_URL_V1}customer/payments/pending/`, {
        headers: mergedHeaders,
      });
      return response.data;
    } catch (e) {
      const axiosError = e as AxiosError;
      return rejectWithValue('The request has failed');
    }
  }
);

export const cancelPendingInvoice = createAsyncThunk<undefined, string, {
  rejectValue: string
}>('CANCEL_PENDING_INVOICE', async (_, {rejectWithValue}) => {
    const mergedHeaders = {
      Authorization: `Token ${(localStorage.getItem('token') || "").replaceAll("\"", "")}`,
    };
    try {
      await axios.delete(`${process.env.NEXT_PUBLIC__APP_API_URL_V1}customer/payments/pending/`, {
        headers: mergedHeaders,
      });
    } catch (e) {
      return rejectWithValue('The request has failed');
    }
  }
);

export const getInvoice = createAsyncThunk<
  InvoiceResponse,
  string,
  { rejectValue: string }>
('GET_INVOICE', async (invoiceId, {rejectWithValue}) => {
    const mergedHeaders = {
      Authorization: `Token ${(localStorage.getItem('token') || "").replaceAll("\"", "")}`,
    };
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC__APP_API_URL_V1}customer/payments/${invoiceId}/`, {
        headers: mergedHeaders,
      });
      return response.data;
    } catch (e) {
      const axiosError = e as AxiosError;
      return rejectWithValue('The request has failed');
    }
  }
);
