import { createSlice } from '@reduxjs/toolkit';
import { EmailAuth, VerifyCodeAnswer } from '@/models/Auth.model';

interface UsersState {
  emailAnswer: EmailAuth | null;
  verifyCodeAnswer: VerifyCodeAnswer | null;
  isLoading: boolean;
  isOpenMenu: boolean;
  error: string;
}

const initialState: UsersState = {
  emailAnswer: null,
  verifyCodeAnswer: null,
  isLoading: false,
  isOpenMenu: false,
  error: '',
};

export const authSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    togleMenu: (state, action) => {
      state.isOpenMenu = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { togleMenu } = authSlice.actions;

export default authSlice.reducer;
