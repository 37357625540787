'use client';
import {FC, useEffect, useState} from 'react';
import styles from './NavBar.module.scss';
import Image from 'next/image';
import Logo from '../../app/assets/icons/logo.svg';
import {useAppDispatch, useAppSelector} from '@/app/redux/hooks';
import Link from 'next/link';
import {useLocale, useTranslations} from 'next-intl';
import ButtonElement from '../ButtonElement';
import user from '../../app/assets/icons/person.svg';
import mountain from '../../app/assets/icons/mountain.svg';
import Letter from '../../app/assets/icons/letter.svg';
import Twitter from '../../app/assets/icons/twitter.svg';
import Telegram from '../../app/assets/icons/telegram-2.svg';
import AuthPopup from '../AuthPopup';
import WaitlistPopupContainer from "@/shared/WaitlistPopupContainer";
import Alert from "@/shared/Alert/Alert";
import {togleMenu} from "@/app/features/MainPage/mainSlice/MainSlice";
import {usePathname, useRouter} from "@/i18n/routing";
import {useParams} from "next/navigation";
import {useLocalStorage} from "usehooks-ts";

interface NavBarProps {
  className?: string;
  isMobile?: boolean;
}

const NavBar: FC<NavBarProps> = ({className = '', isMobile = false}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {isOpenMenu} = useAppSelector((state) => state.authorization);
  const t = useTranslations('BURGER_MENU');
  const ft = useTranslations('FOOTER_COMPONENT');
  const [token, setToken] = useLocalStorage<string>('token', '');
  const [email, setEmail] = useLocalStorage<string>('email', '');
  const [waitlistPopupOpen, setWaitlistPopupOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();


  const handleChangeLanguage = (e: any) => {
    e.preventDefault()
    if (locale.includes('en')) {
      router.replace({pathname}, {locale: 'ua'});
    } else {
      router.replace({pathname}, {locale: 'en'});
    }
  };

  const year = new Date().getFullYear();

  return (
    <aside
      className={`${styles.root} ${
        isOpenMenu && isMobile ? styles.root__open : styles.root__closed
      } ${className}`}>
      <Link prefetch={false} className={styles.navbar__logo} href="/">
        <Image src={Logo} alt="logo"/>
      </Link>

      <nav className={styles.navbar__links}>
        {token && <Alert
            type="default"
            message={t("Alert.Logout.message")}
            title={email || ''}
            buttonText={t("Alert.Logout.button")}
            className={styles.navbar__alert}
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
        />}
        {!token && <Alert
            type="default"
            message={t("Alert.Login.message")}
            title={t("Alert.Login.title")}
            buttonText={t("Alert.Login.button")}
            className={styles.navbar__alert}
            onClick={() => {
              setIsOpen(true);
              dispatch(togleMenu(!isOpenMenu));
            }}
        />}

        {/*<div className={styles.links__container}>*/}
        {/*  <b className={styles.links__title}>EVENTS</b>*/}
        {/*  <Link className={styles.links__elem} href="/">*/}
        {/*    Concerts*/}
        {/*  </Link>*/}
        {/*  <Link className={styles.links__elem} href="/">*/}
        {/*    Meetups*/}
        {/*  </Link>*/}
        {/*  <Link className={styles.links__elem} href="/">*/}
        {/*    Forums*/}
        {/*  </Link>*/}
        {/*</div>*/}

        {/*<div className={styles.links__container}>*/}
        {/*  <b className={styles.links__title}>{t("group.account")}</b>*/}
        {/*  <Link className={styles.links__elem} href={'/public-offer'}>{ft('public_offer')}</Link>*/}
        {/*  <Link className={styles.links__elem} href={'/privacy-policy'}>{ft('privacy_policy')}</Link>*/}
        {/*</div>*/}

        <div className={styles.links__container}>
          <b className={styles.links__title}>{t("group.other")}</b>
          <Link className={styles.links__elem} href={'#'} onClick={handleChangeLanguage}>{t('switch_language')}</Link>
          <Link className={styles.links__elem} href={'/public-offer'}>{ft('public_offer')}</Link>
          <Link className={styles.links__elem} href={'/privacy-policy'}>{ft('privacy_policy')}</Link>
        </div>

        <div className={styles.links__container}>
          <ButtonElement color="dark" className={styles.organizer} text={'sign in'}
                         onClick={() => setWaitlistPopupOpen(true)}>
            <span className={styles.signin__icon}>
              <Image src={mountain} alt="mountain"/>
            </span>
            Organizers room
          </ButtonElement>
        </div>

        <div className={styles.social}>
          <a className={styles.social__link} target="_blank" href="https://t.me/gligleapp">
            <Image src={Telegram} alt="telegram"/>
          </a>
          <a className={styles.social__link} target="_blank" href="https://twitter.com/gligleapp">
            <Image src={Twitter} alt="Twitter"/>
          </a>
          <a
            className={`${styles.social__link} ${styles.social__letter}`}
            target="_blank"
            href="mailto:support@gligle.app">
            <Image src={Letter} alt="Letter"/>
          </a>
        </div>

        <footer className={styles.footer}>
          <b className={styles.text__bold}>Gligle, 2022-{year}</b>
          <p className={styles.text__default}>
            {ft('use')}<br/>
          </p>
        </footer>
      </nav>

      <AuthPopup setIsOpen={() => setIsOpen(false)} isOpen={isOpen}/>
      <WaitlistPopupContainer
        isOpen={waitlistPopupOpen}
        setIsOpen={setWaitlistPopupOpen}
      />
    </aside>
  );
};

export default NavBar;
