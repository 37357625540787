import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { sendEmail, sendCode } from './MainActions';
import { EmailAuth, VerifyCodeAnswer } from '@/models/Auth.model';

interface UsersState {
  emailAnswer: EmailAuth | null;
  verifyCodeAnswer: VerifyCodeAnswer | null;
  isLoading: boolean;
  isOpenMenu: boolean;
  error: string;
}

const initialState: UsersState = {
  emailAnswer: null,
  verifyCodeAnswer: null,
  isLoading: false,
  isOpenMenu: false,
  error: '',
};

export const mainSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    togleMenu: (state, action) => {
      state.isOpenMenu = action.payload;
    },
  },
  extraReducers: (builder) => {
    // EMAIL-AUTHORIZATION
    builder.addCase(sendEmail.fulfilled, (state, action: PayloadAction<EmailAuth>) => {
      state.isLoading = false;
      state.error = '';
      state.emailAnswer = action.payload;
    });
    builder.addCase(sendEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendEmail.rejected, (state) => {
      state.isLoading = false;
      state.error = '';
    });

    // VERIFY-CODE
    builder.addCase(sendCode.fulfilled, (state, action: PayloadAction<VerifyCodeAnswer>) => {
      state.isLoading = false;
      state.error = '';
      state.verifyCodeAnswer = action.payload;
      localStorage.setItem('token', action.payload.key);
    });
    builder.addCase(sendCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendCode.rejected, (state) => {
      state.isLoading = false;
      state.error = '';
    });
  },
});

export const { togleMenu } = mainSlice.actions;

export default mainSlice.reducer;
