'use client';
import { FC } from 'react';
import styles from './RootWrapper.module.scss';
import { usePathname } from 'next/navigation';

interface RootWrapperProps {
  className?: string;
  children: React.ReactNode;
}

const RootWrapper: FC<RootWrapperProps> = ({ className = '', children }) => {
  const path = usePathname();
  const pageWithoutHeader = path.includes('/event/') || path.includes('/buy-ticket/');
  return (
    <main className={`${styles.main} ${pageWithoutHeader && styles.main__woheader}`}>
      {children}
    </main>
  );
};

export default RootWrapper;
